import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const history = useNavigate();

  const handleButtonClick = () => {
    history('/upcoming-events');
  };

  return (
    <header className="header">
      <div className="header-content">
        <h1>Find your next experience</h1>
        <button className="navigate-button" onClick={handleButtonClick}>
          Browse Events
        </button>
      </div>
    </header>
  );
};

export default Header;
