// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD49n-PB7QJ9pguyqRqSU4N5l0NnItkHrM",
  authDomain: "qpid-dba91.firebaseapp.com",
  projectId: "qpid-dba91",
  storageBucket: "qpid-dba91.appspot.com",
  messagingSenderId: "688818987740",
  appId: "1:688818987740:web:14e7597f66f2d2fe570a19",
  measurementId: "G-FYGW9HB4VF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
