import React from 'react';
import './Footer.css';
import appStoreImg from '../assets/app-store.png'; // Update the path as needed
import playStoreImg from '../assets/google-play.png'; // Update the path as needed

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Get Our App</h3>
          <div className="app-links">
            <a href="https://apps.apple.com/ke/app/qpid-events-connect-more/id6449599254" target='_blank' rel='noreferrer' className="app-store-link">
              <img src={appStoreImg} alt="App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.qpid.Qpid" target='_blank' rel='noreferrer' className="play-store-link">
              <img src={playStoreImg} alt="Play Store" />
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Have a question?</h3>
          <a href="mailto:qpidke@gmail.com" target='_blank' rel='noreferrer' className="email-link">qpidke@gmail.com</a>
        </div>
        <div className="footer-section">
          <h3>Location</h3>
          <p>Langata, Nairobi West</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Qpid Technologies</p>
        <div className="footer-links">
          <a href="https://qpid.co.ke/terms_and_conditions.html" target='_blank' rel='noreferrer'>Terms & Conditions</a>
          <a href="https://qpid.co.ke/privacy.html" target='_blank' rel='noreferrer'>Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;