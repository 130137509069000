import React, { useEffect, useState } from "react";
import EventCard from "../../utils/EventCard";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, limit, where } from "firebase/firestore";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EventList.css";

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const q = query(
          collection(db, "events"),
          where("attendees", ">=", 200),
          where("attendees", "<", 1000),
          where("isPaid", "==", true),
          where("isTicketed", "==", true),
          // where("isVisible", "==", true), This will be in production do not uncomment it
          limit(4)
        );
        const querySnapshot = await getDocs(q);
        const eventsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsData);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        setError("Error fetching events: " + error.message);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchEvents();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleEventClick = (id) => {
    navigate(`/event-purchase/${id}`);
  };

  if (loading) {
    return (
      <div className="loading-message">
        <div>Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        <div>Error: {error}</div>
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    centerMode: true,
    centerPadding: "50px",
    className: "center",
    draggable: false,
    touchMove: false,
  };

  return (
    <div className="event-list-container">
      {isMobile ? (
        <Slider {...settings}>
          {events.map((event) => (
            <div className="event-wrapper" key={event.id}>
            <button
              onClick={() => handleEventClick(event.id)}
              className="event-button"
              aria-label={`View details for ${
                event.eventName ?? "Unnamed event"
              }`}
            >
              <EventCard
                title={event.eventName ?? "No Title"}
                date={
                  event.eventStartTime
                    ? new Date(event.eventStartTime).toLocaleString()
                    : "No Date"
                }
                image={event.eventBannerUrl ?? "default-image-url"}
              />
            </button>
          </div>
          ))}
        </Slider>
      ) : (
        <div className="event-list">
          {events.map((event) => (
            <button
              key={event.id}
              onClick={() => handleEventClick(event.id)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleEventClick(event.id);
                }
              }}
              className="event-card-button"
            >
              <EventCard
                title={event.eventName}
                date={new Date(event.eventStartTime).toLocaleString()}
                image={event.eventBannerUrl}
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventList;
