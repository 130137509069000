import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './EventPurchase.css';

// Move TicketCounter component outside of EventPurchase
const TicketCounter = ({ value, onDecrement, onIncrement }) => (
  <div className="ticket-counter">
    <button className="counter-button" onClick={onDecrement}>-</button>
    <span className="counter-value">{value}</span>
    <button className="counter-button" onClick={onIncrement}>+</button>
  </div>
);

const EventPurchase = () => {
  const [step, setStep] = useState(1);
  const [event, setEvent] = useState(null);
  const [ticketQuantities, setTicketQuantities] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventDocRef = doc(db, 'events', id);
        const eventDoc = await getDoc(eventDocRef);

        if (eventDoc.exists()) {
          const eventData = eventDoc.data();

          const mappedEvent = {
            id: eventData.eventId,
            title: eventData.eventName,
            date: new Date(eventData.eventStartTime).toLocaleString(),
            image: eventData.eventBannerUrl,
            eventDescription: eventData.eventDescription,
            eventLocation: eventData.eventLocation,
            tickets: eventData.tickets.map(ticket => ({
              ticketType: ticket.ticketType,
              ticketPrice: ticket.ticketPrice,
            })),
            isPaid: eventData.isPaid,
            isTicketed: eventData.isTicketed,
          };

          console.log('Event data fetched successfully:', mappedEvent);
          setEvent(mappedEvent);
        } else {
          console.log('No such event!');
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    if (id) {
      fetchEventData();
    } else {
      console.log('No event ID provided!');
    }
  }, [id]);

  const handleTicketChange = (type, value) => {
    setTicketQuantities({
      ...ticketQuantities,
      [type]: Math.max(0, value),
    });
  };

  const calculateTotal = () => {
    if (!event?.tickets) return 0;
    return event.tickets.reduce((total, ticket) => {
      const quantity = ticketQuantities[ticket.ticketType] || 0;
      return total + (ticket.ticketPrice * quantity);
    }, 0);
  };

  const initiatePayment = async () => {
    try {
      const payload = {
        tx_ref: `event-${id}-${Date.now()}`,
        amount: calculateTotal(),
        currency: "NGN",
        redirect_url: "https://your-redirect-url.com",
        payment_options: "card, mobilemoneyghana, ussd",
        meta: {
          event_id: id,
        },
        customer: {
          email: "user@example.com",
          phonenumber: "080****4528",
          name: "Customer Name"
        },
        customizations: {
          title: "Event Purchase",
          description: `Payment for ${event?.title}`,
        }
      };

      const response = await fetch('/api/initiate-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();
      window.location.href = data.data.link;
    } catch (error) {
      console.error(error);
    }
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  if (!event) {
    console.log('Event data is still loading or not found.');
    return <div>Loading...</div>;
  }

  const shareUrl = window.location.href;

  return (
    <div className="container">
      <div className="event-purchase">
        <div className="progress-bar">
          <div className={`step ${step >= 1 ? 'active' : ''}`}>1. Event Details</div>
          <div className={`step ${step >= 2 ? 'active' : ''}`}>2. Tickets</div>
          <div className={`step ${step >= 3 ? 'active' : ''}`}>3. Payment</div>
          <div className={`step ${step >= 4 ? 'active' : ''}`}>4. Confirmation</div>
        </div>

        {step === 1 && (
          <div className="step-content">
            <div className="event-layout">
              <div className="event-details">
                <h2 className="event-title">{event.title}</h2>
                <p className="event-field">Date: <span className="event-value">{event.date}</span></p>
                <p className="event-field">Description: <span className="event-value">{event.eventDescription}</span></p>
                <p className="event-field">Location: <span className="event-value">{event.eventLocation}</span></p>
                <div className="share-event">
                  <p className="share-title">Share This Event:</p>
                  <div className="share-icons">
                    <a href={`https://twitter.com/intent/tweet?url=${shareUrl}`} target="_blank" rel="noopener noreferrer" className="share-icon tw">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href={`https://www.instagram.com/?url=${shareUrl}`} target="_blank" rel="noopener noreferrer" className="share-icon ig">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} target="_blank" rel="noopener noreferrer" className="share-icon ln">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href={`https://wa.me/?text=${shareUrl}`} target="_blank" rel="noopener noreferrer" className="share-icon wa">
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </div>
                </div>
                <button className='event-details-button' onClick={nextStep}>Next</button>
              </div>
              <div className="event-image-container">
                <img src={event.image} alt={event.title} className="event-details-image" />
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="step-content">
            <h2 className="ticket-title">TICKETS</h2>
            <table className="ticket-table">
              <thead>
                <tr>
                  <th>TYPE</th>
                  <th>PRICE</th>
                  <th className="quantity-column">QUANTITY</th>
                  <th>SUB-TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {event.tickets.map(ticket => (
                  <tr key={ticket.ticketType} className={`ticket-${ticket.ticketType.toLowerCase()}`}>
                    <td data-label="TYPE">{ticket.ticketType}</td>
                    <td data-label="PRICE">KSH. {ticket.ticketPrice}</td>
                    <td data-label="QUANTITY" className="quantity-column">
                      <TicketCounter
                        value={ticketQuantities[ticket.ticketType] || 0}
                        onDecrement={() => handleTicketChange(ticket.ticketType, (ticketQuantities[ticket.ticketType] || 0) - 1)}
                        onIncrement={() => handleTicketChange(ticket.ticketType, (ticketQuantities[ticket.ticketType] || 0) + 1)}
                      />
                    </td>
                    <td data-label="SUB-TOTAL">KSH. {(ticketQuantities[ticket.ticketType] || 0) * ticket.ticketPrice}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="bottom-row">
              <div className="total-section">
                <span className="total-label">TOTAL:</span>
                <span className="total-amount">KSH. {calculateTotal()}</span>
              </div>
              <div className="button-section">
                <button className="back-button" onClick={prevStep}>Back</button>
                <button className="next-button" onClick={nextStep}>Next</button>
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="step-content">
            <h2 className="payment-title">Payment</h2>
            <button onClick={initiatePayment}>Proceed to Payment</button>
            <button className="back-button" onClick={prevStep}>Back</button>
          </div>
        )}

        {step === 4 && (
          <div className="step-content">
            <h2>Confirmation</h2>
            <p>Your tickets have been purchased!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventPurchase;