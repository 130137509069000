import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Header from './utils/Header';
import Footer from './utils/Footer';
import EventList from './components/home_page/EventList';
import UpcomingEvents from './components/upcoming_events/UpcomingEvents';
import EventPurchase from './components/event_purchase/EventPurchase';

import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <div className="content-wrap">
          <Routes>
            <Route path="/" element={<HeaderAndContent />}>
              <Route index element={<EventList />} />
            </Route>
            <Route path="/upcoming-events" element={<UpcomingEvents />} />
            <Route path="/event-purchase/:id" element={<EventPurchase />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

const HeaderAndContent = () => (
  <>
    <Header />
    <Outlet />
  </>
);

export default App;