import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase"; // Ensure the correct path to your firebase config
import { collection, getDocs, query, where } from "firebase/firestore";
import EventCard from "../../utils/EventCard";
import { FaSearch } from "react-icons/fa";
import "./UpcomingEvents.css";

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsRef = collection(db, "events");
        const q = query(
          eventsRef,
          where("isPaid", "==", true),
          where("isTicketed", "==", true)
          // where("isVisible", "==", true), This will be in production do not uncomment it
        );
        const querySnapshot = await getDocs(q);
        const eventsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsData);
        setFilteredEvents(eventsData);
      } catch (error) {
        console.error("Error fetching events data:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = events.filter((event) =>
      event.eventName?.toLowerCase().includes(term)
    );
    setFilteredEvents(filtered);
  };

  const handleEventClick = (id) => {
    navigate(`/event-purchase/${id}`);
  };

  return (
    <div className="upcoming-events">
      <header>
        <h1>Explore events</h1>
        <div className="filters">
          <button className="filter-button">Today</button>
          <button className="filter-button">This weekend</button>
          <button className="filter-button">Next week</button>
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Search events"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="search-icon">
            <FaSearch style={{ marginBottom: "2px" }} />
          </div>
        </div>
      </header>
      <section className="popular-events">
        <h2 className="section-title">Popular paid and ticketed events</h2>
        <div className="events-grid">
          {filteredEvents.length > 0 ? (
            filteredEvents.map((event) => (
              <div className="event-wrapper" key={event.id}>
                <button
                  onClick={() => handleEventClick(event.id)}
                  className="event-button"
                  aria-label={`View details for ${
                    event.eventName ?? "Unnamed event"
                  }`}
                >
                  <EventCard
                    title={event.eventName ?? "No Title"}
                    date={
                      event.eventStartTime
                        ? new Date(event.eventStartTime).toLocaleString()
                        : "No Date"
                    }
                    image={event.eventBannerUrl ?? "default-image-url"}
                  />
                </button>
              </div>
            ))
          ) : (
            <p>No events found</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default UpcomingEvents;
